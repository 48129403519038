<template>
  <v-container fluid>
    <v-row>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="text-h5">
            <!--Heading-->
            <v-skeleton-loader
              v-if="this.loading === true"
              type="heading"
              min-height="57"
            >
            </v-skeleton-loader>
            <v-chip label color="green lighten-4" close close-icon="mdi-check"
              >Reconciled</v-chip
            >
            <h1 class="pt-3">
              {{
                this.customer_payment_allocation_document
                  .customer_payment_allocation_number
              }}
            </h1>
            <!--End of heading-->
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-col> </v-col>
    </v-row>
    <!--Transaction details-->
    <v-row>
      <v-col
        sm="3"
        md="3"
        v-for="item in [
          { header: 'Bank Transaction #' },
          { header: 'Type' },
          { header: 'Date' },
          { header: 'Amount' },
        ]"
        :key="item.header"
      >
        <h4>{{ item.header }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        sm="3"
        md="3"
        v-for="item in [
          { name: value.transaction_number },
          { name: value.transaction_type },
          { name: value.transaction_date },
          { name: formatAsCurrency('R', value.transaction_amount) },
        ]"
        :key="item.name"
      >
        {{ item.name }}
      </v-col>
    </v-row>
    <!--Divider-->
    <v-row class="my-3">
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <!--Sales Orders-->
    <!--Skeleton loader-->
    <v-skeleton-loader
      v-if="this.loading === true"
      type="table"
      min-height="100"
      max-height="100"
    >
    </v-skeleton-loader>
    <!--End of skeleton loader-->
    <div v-else>
      <!--Customer info-->
      <v-row>
        <v-col sm="6" md="6">
          <h4>Customer Name</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6" md="6">
          {{ this.sales_orders[0].customer.customer_name }}
        </v-col>
      </v-row>
      <!--Divider-->
      <v-row class="my-3">
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <!--Order info-->
      <v-row>
        <v-col
          sm="3"
          md="3"
          v-for="item in [
            { header: 'Order #' },
            { header: 'Order Date' },
            { header: 'Amount Paid' },
            { header: 'Amount Due' },
          ]"
          :key="item.header"
        >
          <h4>{{ item.header }}</h4>
        </v-col>
      </v-row>
      <v-row v-for="item in this.sales_orders" :key="item.order_id">
        <v-col sm="3" md="3">
          <router-link
            :to="{ name: 'view-order', params: { order_id: item.order_id } }"
          >
            {{ item.order_number }}
          </router-link>
        </v-col>
        <v-col
          sm="3"
          md="3"
          v-for="data in [
            { name: item.order_date },
            { name: formatAsCurrency('R', item.order_amount_paid) },
            { name: formatAsCurrency('R', item.order_amount_due) },
          ]"
          :key="data.name"
        >
          {{ data.name }}
        </v-col>
      </v-row>
      <!--Underpaid amounts-->
      <customer-payment-allocation-summary-underpaid
        :sales_orders="this.sales_orders"
        :customer_payment_allocation_document="
          this.customer_payment_allocation_document
        "
      >
      </customer-payment-allocation-summary-underpaid>
      <!--Divider-->
      <v-row class="my-3">
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <!--Overpaid Amount-->
      <customer-payment-allocation-summary-overpaid
        :customer_payment_allocation_document="
          this.customer_payment_allocation_document
        "
      >
      </customer-payment-allocation-summary-overpaid>
      <!--Remove and Redo-->
      <v-card-actions>
        <v-dialog v-model="remove_and_redo_dialog" max-width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-spacer></v-spacer>
            <v-btn color="red" outlined v-bind="attrs" v-on="on">
              Remove & Redo
            </v-btn>
          </template>
          <customer-payment-allocation-remove-redo-dialog
            :remove_and_redo_dialog="this.remove_and_redo_dialog"
            :value="this.value"
            :sales_orders="this.sales_orders"
            :cpa_doc="this.customer_payment_allocation_document"
            @changeRemoveDialog="changeRemoveDialog($event)"
            @closeDialog="closeDialog($event)"
          >
          </customer-payment-allocation-remove-redo-dialog>
        </v-dialog>
      </v-card-actions>
    </div>
  </v-container>
</template>
<script>
import {
  getCPADocument,
  getSalesOrderDocuments,
  returnUnderpaidAmount,
} from "../data/external_customer_payment_allocation";

import { formatAsCurrency } from "../../../composables/external";

export default {
  name: "CustomerPaymentAllocationSummary",
  props: ["value"],
  components: {
    CustomerPaymentAllocationRemoveRedoDialog: () =>
      import(
        /* webpackPrefetch: true */ "./CustomerPaymentAllocationRemoveRedoDialog.vue"
      ),
    CustomerPaymentAllocationSummaryUnderpaid: () =>
      import("./CustomerPaymentAllocationSummaryUnderpaid"),
    CustomerPaymentAllocationSummaryOverpaid: () =>
      import("./CustomerPaymentAllocationSummaryOverpaid"),
  },
  data() {
    return {
      customer_payment_allocation_document: {},
      sales_orders: [],
      loading: false,
      remove_and_redo_dialog: false,
    };
  },
  created() {
    this.loading = true;
    //  Get payment allocation details
    this.getCPADocument();
  },
  computed: {},
  methods: {
    formatAsCurrency,
    getCPADocument,
    getSalesOrderDocuments,
    returnUnderpaidAmount,
    changeRemoveDialog() {
      this.remove_and_redo_dialog = false;
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>
