import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import { showSnackbar } from '../../../globalActions'
import { firebaseTimestamp, newDateToISO } from '../../../composables/external'

//  COMPUTED Properties
export const amountAllocated = function () {
    return this.selected_sales_orders.reduce((total, sales_order) => {
        return (total + sales_order.newAllocatedPortion)
    }, 0) + this.overpaid_amount // ensures overpaid transactions can be reconciled
}
export const formatAmountLeftToAllocate = function () {
    if (this.amount_left_to_allocate === 0) {
        return 'success--text'
    }
    else {
        return ''
    }
}
//  Used to work out total of all past payments per job
export const sumAllocatedPortions = function (item) {
    return item.reduce((total, portion) => {
        return (total + portion.amount)
    }, 0)
}
//  BankStatementsCustomerPaymentAllocation Methods
//__________________________________________________
//  Styles sales orders that are an exact match for payment allocations
export const formatItemClass = function (item) {
    return this.value.transaction_amount === item.order_amount_due && this.amountAllocated === 0 ?
        'green lighten-4' : ''
}

//  Retreive sales orders from database
export const getSalesOrders = function () {
    this.selected_sales_orders = []
    const docRef = db.collection('sales_orders').where('customer.customer_id', '==', this.selected_customer).where('order_amount_due', '>', 0)
    docRef.onSnapshot(querySnapshot => {
        this.sales_orders = []
        querySnapshot.forEach(doc => {
            const data = doc.data()
            //Pushes the categories objects to the empty categories array above
            this.sales_orders.push(data)
        })
    })
}

export const amountLeftToAllocate = function (value) {
    return this.amount_left_to_allocate = value.transaction_amount - this.amountAllocated
}

export const allocateToSalesOrder = function (item) {
    //  Ensures that amount_paid returns to its original value before it was allocated
    if (item.value === false)
        return item.item.newAllocatedPortion -= item.item.newAllocatedPortion

    if (this.amount_left_to_allocate >= 0 && item.item.order_value <= this.amount_left_to_allocate) {
        item.item.newAllocatedPortion = item.item.order_value - item.item.order_amount_paid
    } else {
        item.item.newAllocatedPortion = this.amount_left_to_allocate
    }
}

//  CREATED Lifecycle hook methods
//_______________________________________________
export const getCustomers = function () {
    db.collection('customers').where('customer_status', '==', 'Available').get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                const data = doc.data()
                this.customers.push(data)
            })
                .catch(err => {
                    console.log(err)
                })
        })
}
//  //Format CPA number
export const getGPACounter = function () {
    const CPARef = db.collection('customer_payment_allocation_counter').doc('vmuYfD6V00arfWkGI3M3')
    CPARef.onSnapshot(snapshot => {
        const CPA_count = snapshot.data().CPA_count
        this.customer_payment_allocation_number = CPA_count < 1000 ?
            `CPA-${String("00000" + CPA_count).slice(-4)}` : `CPA-${CPA_count}`
    })
}

// RECONCILE TRANSACTION FUNCTIONS
//_______________________________________________
//  Step 1: Create a new customer payment allocation document
export const createNewCPA = function () {
    const newDocRef = db.collection('customer_payment_allocations').doc()
    this.customer_payment_allocation_id = newDocRef.id
    newDocRef.set({
        customer_payment_allocation_id: newDocRef.id,
        customer_payment_allocation_bank_transaction: this.value.transaction_id,
        customer_payment_allocation_number: this.customer_payment_allocation_number,
        overpaid_amount: this.overpaid_amount,
        customer_payment_allocation_date_added: newDateToISO(),
        customer_payment_allocation_timestamp: firebaseTimestamp(),
    })
        .then(() => { this.increaseCounter() })
        .catch((error) => { console.log(`Error message: ${error}`) })
}
//  Step 1.2:
export const increaseCounter = function () {
    const db = firebase.firestore()
    const increment = firebase.firestore.FieldValue.increment(1)
    const increaseCount = db.collection('customer_payment_allocation_counter').doc('vmuYfD6V00arfWkGI3M3')
    increaseCount.update({ CPA_count: increment })
}

//  Step 2: Retrieve the new document so we can add it to sales orders + bank transaction
export const retrieveAndLinkNewCPADoc = function () {
    var docRef = db.collection("customer_payment_allocations").doc(this.customer_payment_allocation_id)
    docRef.get()
        .then((cpa_document) => {
            //  2.1:  Add CPA id to all sales order docs
            this.linkCPAtoSalesOrders(cpa_document.id)
            //  2.2:  Add CPA id to bank transaction doc
            this.linkCPAtoBankTransaction(cpa_document.id)
        }).catch((error) => {
            console.log("Error getting document:", error);
        })
}
//  Step 2.1:  Merge CPA id to CPA array in sales orders 
//----->    REWORK
export const linkCPAtoSalesOrders = function () {
    this.selected_sales_orders.forEach(async element => {
        //  Ensures no undefined values are added to db
        const allocatedPortionObj = {
            ...{
                amount: element.newAllocatedPortion,
                cpa_id: this.customer_payment_allocation_id,
                cpa_number: this.customer_payment_allocation_number,
                transaction_date: this.value.transaction_date,
                bank_account_name: this.value.bank_account_name,
                bank_account_id: this.value.bank_account_id,
            },
            ...(element.order_underpaid_amount === undefined ? {} : { underpaid: Number(element.order_underpaid_amount) })
        }
        const newAmountPaid = element.newAllocatedPortion + element.order_amount_paid
        const newAmountDue = element.order_value - (newAmountPaid) - (element.order_underpaid_amount === undefined ? 0 : Number(element.order_underpaid_amount))
        const salesOrderRef = db.collection("sales_orders").doc(element.order_id)
        try {
            await salesOrderRef.update({
                customer_payment_allocation_id: firebase.firestore.FieldValue.arrayUnion(this.customer_payment_allocation_id),
                order_amount_paid: newAmountPaid,
                order_amount_due: newAmountDue,
                //Need to assign this so that orders remember part payments if a CPA is deleted
                order_allocated_portion: firebase.firestore.FieldValue.arrayUnion(allocatedPortionObj),
            })
            console.log("Sales order successfully updated!")
        }
        catch (error) {
            console.error("Error updating document: ", error)
        }
    })
}
//  Step 3:
export const linkCPAtoBankTransaction = function () {
    var bankTransactionRef = db.collection("bank_transactions").doc(this.value.transaction_id)
    bankTransactionRef.update({
        customer_payment_allocation_id: this.customer_payment_allocation_id,
        transaction_allocation_status: 'Customer Payment'

    })
        .then(console.log("Bank transaction successfully updated!"))
        .catch((error) => console.error("Error updating document: ", error))
}
//  Step 4:
export const reconcileTransaction = function () {
    //  Create new CPA
    this.createNewCPA()
    //  Add link to CPA to all selected_sales_orders
    this.retrieveAndLinkNewCPADoc()
    //  Add link to CPA on bank_transaction
    showSnackbar("New customer payment has been allocated")
}

//  OVERPAID Methods
//_______________________________________________

export const changeApplyOverpaid = function () {
    this.applyOverpaid = !this.applyOverpaid
    this.resetOverpaid = false
}

export const setOverpaidAmount = function () {
    if (this.applyOverpaid === false) {
        return this.overpaid_amount = 0
    }
    const sumAllocatedPortions = this.selected_sales_orders.reduce((total, sales_order) => {
        return (total + sales_order.newAllocatedPortion)
    }, 0)
    return this.overpaid_amount = this.value.transaction_amount - sumAllocatedPortions
}
//  Ensures that the overpaid amount can be removed if required
export const setOverpaidTrigger = function () {
    if (
        this.selected_sales_orders.length === this.sales_orders.length
        && this.amount_left_to_allocate !== 0
        && this.selected_sales_orders.length > 0
    ) {
        return this.triggerOverpaid = true
    }
}

//  UNDERPAID METHODS
//_______________________________________________

//  Display underpaid value on CPA summary
export const returnUnderpaidAmount = function (item) {
    return item.order_allocated_portion.find(x => x.cpa_id === this.customer_payment_allocation_document.customer_payment_allocation_id)
        .underpaid
}
//  Returns true if any allocated portion arrays contain numbers related to underpayments
export const triggerUnderpaid = function () {
    const evaluatedArray = []
    this.sales_orders.forEach(order => {
        evaluatedArray.push(order.order_allocated_portion.some(portion => isNaN(portion.underpaid) === false))
    })
    return evaluatedArray.includes(true)
}

//  REMOVE & UNRECONCILE CUSTOMER PAYMENTS
//_______________________________________________
//Step 1: 
export const unreconcileSalesOrders = function () {
    this.sales_orders.forEach(async order => {
        const salesDocRef = db.collection("sales_orders").doc(order.order_id)
        const removedPortion = order.order_allocated_portion
            .find(x => x.cpa_id === this.cpa_doc.customer_payment_allocation_id)
        try {
            await salesDocRef.update({
                order_amount_paid: firebase.firestore.FieldValue.increment((removedPortion.amount * -1)),
                order_amount_due: firebase.firestore.FieldValue.increment(removedPortion.amount + (removedPortion.underpaid !== undefined ? removedPortion.underpaid : 0)),
                customer_payment_allocation_id: firebase.firestore.FieldValue.arrayRemove(this.cpa_doc.customer_payment_allocation_id),
                order_allocated_portion: firebase.firestore.FieldValue.arrayRemove(removedPortion),
            })
            console.log('Sales orders successfully unreconciled')
            this.unreconcileBankTransactions()
        } catch (err) {
            console.log(err)
        }
    })
}
//  Step 2:
export const unreconcileBankTransactions = async function () {
    var bankDocRef = db.collection("bank_transactions").doc(this.value.transaction_id)
    try {
        await bankDocRef.update({
            customer_payment_allocation_id: firebase.firestore.FieldValue.delete(),
            transaction_allocation_status: 'No'
        })
        this.deleteCPADocument()
    }
    catch { (error) => error }
}
//  Step 3:
export const deleteCPADocument = async function () {
    try {
        await
            db.collection("customer_payment_allocations").doc(this.this.cpa_doc.customer_payment_allocation_id)
                .delete()
        console.log('Customer payment deleted successfully')
        this.loading_delete = false
    }
    catch { (error) => error }
}
//  Step 4:
export const unreconcileCPADocument = function () {
    this.loading_delete = true
    this.unreconcileSalesOrders()
    this.passCloseDialog()
    this.passKeyChange()
    showSnackbar('Customer payment deleted successfully')
    //  Close modal and show snackbar
}

//  CUSTOMER PAYMENT SUMMARY
//_______________________________________________
//  Step 1
export const getCPADocument = function () {
    db.collection("customer_payment_allocations").doc(this.value.customer_payment_allocation_id)
        .get()
        .then((doc) => {
            this.customer_payment_allocation_document = doc.data()
        })
        //  Get sales order documents
        .then(this.getSalesOrderDocuments())
        .catch((error) => error)
}
//  Step 2
export const getSalesOrderDocuments = function () {
    db.collection("sales_orders").where("customer_payment_allocation_id", "array-contains", this.value.customer_payment_allocation_id)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                this.sales_orders.push(doc.data())
            })
            this.loading = false
        })
        .catch((error) => error)
}