var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5"},[(this.loading === true)?_c('v-skeleton-loader',{attrs:{"type":"heading","min-height":"57"}}):_vm._e(),_c('v-chip',{attrs:{"label":"","color":"green lighten-4","close":"","close-icon":"mdi-check"}},[_vm._v("Reconciled")]),_c('h1',{staticClass:"pt-3"},[_vm._v(" "+_vm._s(this.customer_payment_allocation_document .customer_payment_allocation_number)+" ")])],1)],1)],1),_c('v-col')],1),_c('v-row',_vm._l(([
        { header: 'Bank Transaction #' },
        { header: 'Type' },
        { header: 'Date' },
        { header: 'Amount' } ]),function(item){return _c('v-col',{key:item.header,attrs:{"sm":"3","md":"3"}},[_c('h4',[_vm._v(_vm._s(item.header))])])}),1),_c('v-row',_vm._l(([
        { name: _vm.value.transaction_number },
        { name: _vm.value.transaction_type },
        { name: _vm.value.transaction_date },
        { name: _vm.formatAsCurrency('R', _vm.value.transaction_amount) } ]),function(item){return _c('v-col',{key:item.name,attrs:{"sm":"3","md":"3"}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1),_c('v-row',{staticClass:"my-3"},[_c('v-col',[_c('v-divider')],1)],1),(this.loading === true)?_c('v-skeleton-loader',{attrs:{"type":"table","min-height":"100","max-height":"100"}}):_c('div',[_c('v-row',[_c('v-col',{attrs:{"sm":"6","md":"6"}},[_c('h4',[_vm._v("Customer Name")])])],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6","md":"6"}},[_vm._v(" "+_vm._s(this.sales_orders[0].customer.customer_name)+" ")])],1),_c('v-row',{staticClass:"my-3"},[_c('v-col',[_c('v-divider')],1)],1),_c('v-row',_vm._l(([
          { header: 'Order #' },
          { header: 'Order Date' },
          { header: 'Amount Paid' },
          { header: 'Amount Due' } ]),function(item){return _c('v-col',{key:item.header,attrs:{"sm":"3","md":"3"}},[_c('h4',[_vm._v(_vm._s(item.header))])])}),1),_vm._l((this.sales_orders),function(item){return _c('v-row',{key:item.order_id},[_c('v-col',{attrs:{"sm":"3","md":"3"}},[_c('router-link',{attrs:{"to":{ name: 'view-order', params: { order_id: item.order_id } }}},[_vm._v(" "+_vm._s(item.order_number)+" ")])],1),_vm._l(([
          { name: item.order_date },
          { name: _vm.formatAsCurrency('R', item.order_amount_paid) },
          { name: _vm.formatAsCurrency('R', item.order_amount_due) } ]),function(data){return _c('v-col',{key:data.name,attrs:{"sm":"3","md":"3"}},[_vm._v(" "+_vm._s(data.name)+" ")])})],2)}),_c('customer-payment-allocation-summary-underpaid',{attrs:{"sales_orders":this.sales_orders,"customer_payment_allocation_document":this.customer_payment_allocation_document}}),_c('v-row',{staticClass:"my-3"},[_c('v-col',[_c('v-divider')],1)],1),_c('customer-payment-allocation-summary-overpaid',{attrs:{"customer_payment_allocation_document":this.customer_payment_allocation_document}}),_c('v-card-actions',[_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-spacer'),_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" Remove & Redo ")])]}}]),model:{value:(_vm.remove_and_redo_dialog),callback:function ($$v) {_vm.remove_and_redo_dialog=$$v},expression:"remove_and_redo_dialog"}},[_c('customer-payment-allocation-remove-redo-dialog',{attrs:{"remove_and_redo_dialog":this.remove_and_redo_dialog,"value":this.value,"sales_orders":this.sales_orders,"cpa_doc":this.customer_payment_allocation_document},on:{"changeRemoveDialog":function($event){return _vm.changeRemoveDialog($event)},"closeDialog":function($event){return _vm.closeDialog($event)}}})],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }